.OrdenesWidgetCard {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 1em;
  margin: 1em;
  position: relative;
  background-color: white;
  width: 500px;
}

.OrdersList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.OrdersList > div {
  display: flex;
  flex-direction: column;
  margin: 0 1em;
  flex: 1;
}

.OrdersList > div:first-child {
  flex: 0;
  min-width: 100px;
  margin: unset;
}

.OrdersList > div:last-child {
  flex: 0;
  min-width: 120px;
  margin: unset;
}

.list-group-item:hover {
  background-color: rgb(233, 233, 233);
  cursor: pointer;
}

.list-group {
  max-height: calc(100ch - 15em);
  overflow: auto;
}
