.SideBarContent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  border-right: solid 1px lightgray;
  width: 250px;
}

.menuDistribution {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1em;
}
