.WhiteSpaceContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;
}

.WhiteSpaceContent > img {
  height: 100px;
  width: 100px;
}

.WhiteSpaceContent > label {
  font-size: large;
  font-weight: 500;
}
