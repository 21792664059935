.formulaForm {
  display: flex;
  flex-direction: column;
  /* border: solid 1px lightgray; */
  padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  width: 25em;
  height: 98vh;
  z-index: 1;
}

.formulaForm > div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0.5em 0;
}

.formulaForm > div > div {
  flex: 1;
  font-display: flex;
  flex-direction: column;
}

.formulaForm > div > div.dropdown {
  flex: 0;
}

.formulaForm > div.formControls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.formulaForm > div.formControls > button {
  margin-right: 1em;
}

.listadoPasos {
  display: flex;
  flex-direction: column;
  width: calc(100% - 0.5em);
  position: relative;
}

.itemFormula {
  border: solid 1px lightgray;
  border-radius: 5px;
  margin: 0.3em 0;
  padding: 0.5em;
  position: relative;
  height: 60px;
  overflow: hidden;
  background-color: white;
}

.itemFormula.pausa {
  border: dotted 1px gray;
  background-color: rgb(238, 238, 238);
}

.itemFormula:hover {
  border: solid 1px #ff0000;
}

.itemFormula:hover > div > h2 {
  color: #ff0000;
}

.itemFormula > div.itemOrden {
  position: absolute;
  top: 0;
  right: 15em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3em 0.5em 0 0.5em;
  color: white;
  background-color: #ff0000;
  border-radius: 0 0 5px 5px;
  width: 180px;
}

.itemFormula {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemFormula > div {
  width: 15%;
}

.itemFormula > div:first-child {
  width: 25%;
}

.itemFormula > div:last-child {
  width: 200px;
}

.itemFormula > div > h2 {
  font-size: large;
  font-weight: bold;
}

.itemFormula > div > h1 {
  font-size: xx-large;
  font-weight: bold;
}
.itemFormula > div > h4 {
  font-size: small;
  font-weight: bold;
}

.itemFormula > div > h5 {
  font-size: medium;
  font-weight: normal;
  visibility: hidden;
}

.itemFormula:hover > div > h5 {
  visibility: visible;
}

.itemFormula > div.itemControls {
  visibility: hidden;
}

.itemFormula:hover > div.itemControls {
  visibility: visible;
}

.itemFormula > div > span {
  visibility: hidden;
}

.itemFormula:hover > div > span {
  visibility: visible;
}

.itemFormula > div > small {
  font-weight: bold;
  visibility: hidden;
}

.itemFormula:hover > div > small {
  visibility: visible;
}

.itemFormula > div.itemControls {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}

.sortableHelper {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: white;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: solid 2px #ff0000;
}

.sortableHelper:hover {
  cursor: grab;
}

.PasosForm {
  position: absolute;
  top: 0;
  right: 0;
  height: 98vh;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin: 0.5em;
  z-index: 50;
  border: solid 1px lightgray;
  overflow: hidden;
  box-shadow: -8px 2px 5px -4px rgb(181 181 181 / 75%);
  -webkit-box-shadow: -8px 2px 5px -4px rgb(181 181 181 / 75%);
  -moz-box-shadow: -8px 2px 5px -4px rgba(181, 181, 181, 0.75);
}

h4 > button {
  align-self: flex-end;
}

.ErrorContainer {
  /* border: solid 1px #ff0000;
  border-radius: 5px;
  background-color: rgb(255 231 235);
  padding: 1em;
  margin-right: 1em;*/
  margin: 1em 0em;
  color: #ff0000;
}

.ErrorContainer > label {
  font-weight: bold;
}
