.OrdenCardsList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: auto;
  max-width: calc(100vw - 260px);
}

.OrdenEnCursoCard {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 1em;
  margin: 1em;
  position: relative;
  background-color: white;
  min-width: 250px;
}

.OrdenEnCursoCard:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border: solid 1px #ff0000;
  cursor: pointer;
}

.OrdenEnCursoCard > h1 {
  font-size: large;
}

.OrdenEnCursoCard > h2 {
  font-size: medium;
}

.OrdenEnCursoCard > div {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 1em;
  background-color: #ff0000;
  font-weight: bold;
  padding: 5px;
  color: white;
  border-radius: 0 0 5px 5px;
}
