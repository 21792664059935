.UserContent {
  display: flex;
  flex-direction: row;
  width: 370px;
  align-items: center;
  justify-content: space-around;
}

.UserIcon {
  padding: 11px;
  border-radius: 50px;
  border: solid 1px black;
  display: flex;
  justify-content: center;
  font-size: large;
  height: 42px;
}
