.headerbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em;
}

.headerbar > img {
  height: 4em;
}
