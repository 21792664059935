body > iframe[style*='2147483647']{
  display: none;
}

App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f7f7f7;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-datos {
  background-color: unset;
}

.pagination > button {
  margin-right: 0.5em;
}

.contentpage {
  border-radius: 5px;
  border-color: transparent;
  box-shadow: unset;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  margin: unset;
}

table {
  border: none;
}

.pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  padding: 0.3em 1em;
  top: 0px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.07);

  background-color: #f7f7f7;
}

.btn-primary {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn-outline-primary {
  color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: white !important;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 90, 49, 0.5) !important;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 90, 49, 0.5) !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 90, 49, 0.5) !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn-outline-primary {
  color: #ff0000 !important;
  /* background-color: #fff !important; */
  border-color: #ff0000 !important;
}

.loadingFlag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalForm {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100vh - 0.4em);
  width: 400px;
  background-color: #f7f7f7;
  border: solid 1px lightgray;
  z-index: 1000;
  margin: 0.2em;
  border-radius: 5px;
  box-shadow: -8px 2px 5px -4px rgba(181, 181, 181, 0.75);
  -webkit-box-shadow: -8px 2px 5px -4px rgba(181, 181, 181, 0.75);
  -moz-box-shadow: -8px 2px 5px -4px rgba(181, 181, 181, 0.75);
}

.modalForm > h5 {
  margin: 1em;
}

.modalForm > small {
  margin-left: 1em;
}

.modalForm > h6 {
  margin-left: 1em;
}

.modalForm.large {
  width: 1000px;
}

.form {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 7.4em);
  overflow: auto;
}

.formContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0em 0.2em;
}

.formContent > label {
  margin-top: 0.5em;
}

.formSections {
  flex: 1 1;
  overflow: auto;
  height: calc(100vh - 45px - 0.4em);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.formSections > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1em;
}

.formContent > input.upper {
  text-transform: uppercase;
}

.productoForm > div > input.upper {
  text-transform: uppercase;
}

.formButtons {
  flex: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0.2em 0.2em;
  margin: 1em;
}

.formButtons > button {
  margin-right: 1em !important;
}

.EntityHeader {
  display: flex;
  flex-direction: column;
}

.EntityHeader > h1 {
  font-size: x-large;
  font-weight: bold;
  text-transform: uppercase;
}

.EntityHeader > h2 {
  font-size: large;
  font-weight: bold;
}

.EntityHeader > p {
  font-size: normal;
}

.SubContentTitle {
  font-size: large;
  font-weight: bold;
  margin: 1em;
}

.ContentTitle {
  display: flex;
  justify-content: center;
  font-size: large;
  font-weight: bold;
}

.ContentTitleStart {
  display: flex;
  justify-content: start;
  margin-left: 1em;
  margin-top: 1em;
  font-size: large;
  font-weight: bold;
}

.ProductButtons {
  display: flex;
  flex-direction: row;
  padding: 0 1em;
}

.ProductButtons > button {
  margin-right: 1em;
}

.ProductButtons > button.selected {
  background-color: red;
}

.formContainer {
  margin-left: 1em;
}

.divCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5em;
}

.OrdenesFinalizadas {
  height: 35em;
  overflow: auto;
  margin: 0 1em;
  padding: 0 1em;
  border: solid 1px lightgray;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 2em;
}

.productInfoDetailsContainer {
  display: flex;
  flex-direction: row;
  margin: 1em;
  justify-content:space-around;
}

.productInfoDetailsVerticalContainer {
  display: flex;
  flex-direction: column;
  margin: 1em;
  justify-content:space-around;
}

.orderDetailsContainer {
  display: flex;
  flex-direction: row;
  margin: 1em;
}

.orderDetailsContainer > div {
  padding: 1em;
}

.orderDetailsContainer > div:first-child {
  flex: 1;
}

.orderDetailsContainer > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.OrdenEstadoFinalización {
  display: flex;
  flex-direction: column;
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 1em;
  background-color: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

.seccion {
  padding: 1em;
  border-radius: 5px;
  border: solid 1px lightgray;
  background-color: white;
}

.upper {
  text-transform: uppercase;
}

.controls > button {
  margin: 0.3em;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.horizontal > input {
  margin-right: 0.5em;
}

.horizontal > div {
  min-width: 12em;
}

.widgetSection {
  display: flex;
  flex-direction: column;
}

.widgetSection > div {
  margin-bottom: 2em;
}

.badge {
  padding: 1em !important;
  width: 100%;
  margin: 0px;
  text-align: center;
  border-radius: 5px;
}

.badge-green {
  background-color: green;
  color: white;
}

.badge-red {
  background-color: #f13b3b;
  color: white;
}

.OrdenEstadoFinalización > div {
  width: 100%;
}

.Fabricaciones {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: dotted 1px lightgray;
}

.Fabricaciones > label {
  font-size: large;
  font-weight: bolder;
  padding: 0.5em 1em;
  background-color: #ff0000;
  border-radius: 5px;
  color: white;
  margin-bottom: 0.5em;
}

.widgetColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.widgetRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
