.contenedor {
  color: black;
  margin-left: 190px !important;
  margin-right: 10px !important;
  margin-top: 5px !important;
  /*background-color: #f0f0f2;*/
}

.layout {
  display: flex;
  flex-direction: row;
}

.layoutContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.layoutChildContent {
  overflow: auto;
  height: calc(100vh - 4em);
  /* padding: 1em 1em 1em 1em; */
}
