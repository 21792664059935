.menuContent {
  display: flex;
  flex-direction: column;
}

.menuItem {
  padding: 0.1em 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: normal;
  color: black;
  text-decoration: none;
}

.menuItem > svg {
  margin: 0.5em;
  width: 20px;
  height: 20px;
}

/* active styles */
.menuItem.active {
  border-left: solid 4px #ff0000;
  font-weight: bolder;
}

.menuItem.active > svg {
  color: #ff0000;
}

/* hover styles */
.menuItem:hover > svg {
  color: #ff0000;
}

.menuItem:hover {
  /* font-weight: 600; */
  background-color: lightgray;
  color: black;
  text-decoration: none;
}
