.tableItem {
  border-radius: 5px;
  border: solid 1px lightgray;
  padding: 0.5em;
  margin: 0.5em 0;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.tableItemHeader {
  width: 25%;
  margin-right: 1em;
}

.tableItemHeader > h2 {
  font-size: large;
  font-weight: bolder;
  text-transform: uppercase;
}

.tableItemHeader > small {
  font-size: 7pt;
  font-weight: bold;
}

.tableItemHeader > p {
  font-size: 10pt;
  color: rgb(99, 99, 99);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 4em; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.tableItemHeader > label {
  text-transform: lowercase;
  text-transform: capitalize;
}

.tableItem > .controls > button {
  border: none;
  font-size: small;
  border-radius: 2px;
  padding: 3px 10px;
  flex-wrap: wrap;
  margin: 2px;
}

.tableItem > .controls > button:hover {
  background-color: lightgrey;
}

.tableItem:hover {
  border-color: #ff0000;
}

.tableItem:hover > .tableItemHeader > h2 {
  color: #ff0000;
}

.tableItem:hover > .controls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  visibility: visible;
  opacity: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tableItem > .controls {
  flex: 1;
  visibility: hidden;
  opacity: 0;
  border-left: dotted 1px lightgray;
  border-radius: 5px;
  padding: 10px;
  transition: visibility 0s, opacity 0.4s ease-in-out;
}

.searchBar {
  display: flex;
  flex-direction: row;
}

.searchBar > button {
  margin-left: 1em;
}

.stycky-top {
  position: -webkit-sticky !important;
  position: sticky;
  top: 3em;
  z-index: 1;
}
