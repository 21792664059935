.tableItemHeader > h4 {
  font-size: medium;
  font-weight: bolder;
  text-transform: uppercase;
}

.StockBajo {
  color: red;
  text-align: center;
}

.StockOk {
  color: Green;
  text-align: center;
}

.stycky-top {
  position: -webkit-sticky !important;
  position: sticky;
  top: 5em;
  z-index: 1;
}
