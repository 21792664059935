.ordenFormFooter {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #f7f7f7;
  border-top: solid 1px lightgray;
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
}

.productoForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productoForm > div.NombreProducto {
  flex: 1;
}

.productoForm > div {
  margin-right: 1em;
}

.textFieldError {
  border: solid 1px red;
  border-radius: 4px;
}

.ErrorContainer > ul {
  margin-bottom: unset;
}
