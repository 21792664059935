.formOrderAdditionalComponents {
  flex: 0;
  display: flex;
  flex-direction: row;
  margin: 1em;
  /* min-width: 700px; */
}

.formOrderAdditionalComponents > div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 1em;
  margin-right: 1em;
}

.formOrderAdditionalComponents > div:first-child {
  min-width: 300px;
}

.formButtons.baseBottom {
  justify-content: flex-end;
  position: relative;
}

.formButtons.baseBottom > button {
  position: absolute;
  top: 1em;
  right: -2em;
}
