.contentpage {
  margin: 10px;
  padding: 20px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: gray;
  box-shadow: 15px 0px 23px -12px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 15px 0px 23px -12px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 15px 0px 23px -12px rgba(0, 0, 0, 0.8);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom: solid 1px lightgrey;
}
